<template>
  <div class="text-justify">
    <h1 class="text-center mt-16">
      Welcome to your Dashboard<br />
      <span class="success--text">{{ curUser.Fullname }}</span>
    </h1>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["curUser"]),
  },
};
</script>
